import React from "react";
import theme from "theme";
import { Theme, Link, Text, Section, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				P2PMT
			</title>
		</Helmet>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			align-items="stretch"
			height="fit-content"
			display="inline"
			lazy-load
		>
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline2"
				md-font="--headline2"
				margin="20px 0 0 0"
				align-self="flex-start"
			>
				About us
			</Text>
			<Text
				as="p"
				font="--lead"
				margin="20px 0 20px 0"
				text-align="left"
				display="flex"
				align-self="flex-start"
				width="50% content-box"
			>
				P2PMT is a fintech company that develops software for payment system operators and banks for money transfers.
				<br />
				Our main product is an IT platform for transfers by phone number, using
which payment system operators provide banks and their customers with
first-class money transfer services, so banks can use it via API.{"\n\n\n\n\n\n"}
			</Text>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			align-items="stretch"
			display="inline"
			height="fit-content"
		>
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline2"
				md-font="--headline2"
				margin="20px 0 0 0"
				align-self="flex-start"
			>
				P2PMT Software for the Fintech Industry
			</Text>
			<Text
				as="p"
				font="--lead"
				margin="20px 0 20px 0"
				text-align="left"
				display="flex"
				align-self="flex-start"
				width="50% content-box"
			>
				With the software and services P2PMT, the bank can provide cross-border,
domestic and intra-bank transfer services to customers. The revolutionary
mechanics of the service, automated on the P2PMT platform, gives the bank the
opportunity to simply and conveniently provide all services in one interface of
its own mobile bank, or use the mobile application of the payment system
operator.{" \n\n"}
				<br />
				<br />
				The P2PMT IT platform is open for integration with local payment methods
and transfer payouts, including cash transfer payouts. At the same time, the
platform ensures the exchange of information between banks, the processing and
storage of personal data in accordance with the requirements of national and
international legislation.{"\n\n\n\n\n\n\n\n\n\n\n\n"}
			</Text>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			align-items="stretch"
			height="auto"
			display="inline"
		>
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline2"
				md-font="--headline2"
				margin="20px 0 0 0"
				align-self="flex-start"
			>
				Our clients{"\n\n\n\n\n\n"}
			</Text>
			<Text
				as="p"
				font="--lead"
				margin="20px 0 20px 0"
				text-align="left"
				display="flex"
				align-self="flex-start"
				width="50% content-box"
			>
				Our clients are payment system operators:.{"\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n"}
			</Text>
			<List
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 20px"
				list-style-type="disc"
				as="ul"
				align-self="flex-start"
			>
				<Text margin="0px 0px 0px 0px" font="--lead">
					QULAY PUL (Uzbekistan)
				</Text>
				
			</List>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0" align-items="stretch" display="inline">
			<Override slot="SectionContent" align-items="center" />
			<Text
				as="h2"
				font="--headline2"
				md-font="--headline2"
				margin="20px 0 0 0"
				align-self="flex-start"
			>
				Contacts
			</Text>
			<Text
				as="p"
				font="--lead"
				margin="20px 0 20px 0"
				text-align="left"
				display="flex"
				align-self="flex-start"
				width="50% content-box"
			>
				10A Rue du Puits, 2355 Luxembourg
				<br />
				+352621704693
			</Text>
		</Section>
		
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6385a22721e43d00209ac71a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});
